

import { filter } from 'rxjs';

import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { TranslitPipe } from '@app/pipes/translit.pipe';
import { UtilsService } from './utils.service';

@Injectable({ providedIn: 'root' })
export class PwaUpdateService {
  private translitPipe = new TranslitPipe();
  public snackbar = inject(MatSnackBar);

  private swUpdate = inject(SwUpdate);

  listenPwaUpdates() {
    this.swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(_ => {
        this.swUpdate.activateUpdate().then(() => {
          const message = this.translitPipe.transform('Ажурирање апликације...');
          UtilsService.openSnackbar(message);
          setTimeout(() => {
            document.location.reload();
          }, 1000);
        });
      });
  }
}
