import { map, Observable, startWith } from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, inject, ViewChild
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { DefaultLogoDirective } from '@app/directives/default-logo.directive';
import { TranslitPipe } from '@app/pipes/translit.pipe';
import { Tag, TagsService } from '@app/services/tags.service';
import { Person, PersonsService } from '@services/persons.service';
import { UtilsService } from '@services/utils.service';

@Component({
  selector: 'app-search-dialog',
  imports: [CommonModule, MatAutocompleteModule, MatFormFieldModule,
    DefaultLogoDirective, MatButtonModule, MatInputModule,
    ReactiveFormsModule, MatIconModule, TranslitPipe],
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchDialogComponent {
  @ViewChild("searchInput") searchInput!: ElementRef;

  private changeDetectorRef = inject(ChangeDetectorRef);
  private personsService = inject(PersonsService);
  private tagsService = inject(TagsService);
  private destroyRef = inject(DestroyRef);

  private router = inject(Router);
  private list: any[] = [];
  private storage = 'recent-searches';
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public control = new FormControl('');
  public recentSearches: Person[] = [];
  public filteredItems$!: Observable<any[]>;
  public showThreeLetterWarning = true;
  public showNoResultWarning = false;
  public cities: Tag[] = [];
  public persons: Person[] = [];
  public surnameList: any[] = [];

  constructor(public dialogRef: MatDialogRef<SearchDialogComponent>) { }

  ngOnInit() {
    this.setListener();
    this.getItems();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  async getItems() {

    this.tagsService.getItems()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        const cities = res
          .filter(t => t.region)
          .map(t => {
            const commonName = t.name;
            const fullTitle = t.licencePlate === 'БГ'
              ? t.region : `${t.region} округ`;
            return { ...t, commonName, fullTitle };
          });

        this.cities = cities;
        this.handleLists();
      })

    this.personsService.getItems()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(res => {
        this.persons = res;
        this.handleSurnames();
      })
  }

  handleSurnames() {
    const allSurnames = UtilsService
      .groupAndSortBy(this.persons, 'lastName');
    this.surnameList = [...allSurnames].map(surname => {
      return {
        commonName: surname.title,
        title: surname.title,
        slug: UtilsService.slugify(surname.title),
      }
    });
    this.handleLists();
  }

  handleLists() {
    this.list = [...this.persons, ...this.cities, ...this.surnameList];

    this.list.sort((a, b) => {

      if (!a.region && b.region) {
        return 1;
      } else if (!b.region && a.region) {
        return -1;
      } else if (b.title) {
        return 1;
      } else if (a.title) {
        return -1;
      } else if (!a.frontPage && b.frontPage) {
        return 1;
      } else if (a.frontPage && !b.frontPage) {
        return -1;
      } else if (!a.topTen && b.topTen) {
        return 1;
      } else if (a.topTen && !b.topTen) {
        return -1;
      } else if (!a.localHero && b.localHero) {
        return 1;
      } else if (a.localHero && !b.localHero) {
        return -1;
      } else if (!a.commonName) {
        return 1;
      } else if (!b.commonName) {
        return -1;
      } else {
        return a.commonName.localeCompare(b.commonName);
      }
    });

    this.getRecentSearches();
  }

  setListener() {
    this.filteredItems$ = this.control.valueChanges.pipe(
      startWith(''),
      map(item => this.filterList(item || '')),
    );
  }

  filterList(value: string): any[] {
    const filterValue = value.toLowerCase();

    const textSlugify: string = UtilsService.slugify(filterValue);

    if (textSlugify.length < 3) {
      this.showThreeLetterWarning = true;
      this.showNoResultWarning = false;
      return [];
    }

    const result = this.list.filter(e => {
      const commonNameSlugify = UtilsService.slugify(e.commonName);
      const nameMatches = commonNameSlugify.toLowerCase().includes(textSlugify);
      if (nameMatches) {
        return true;
      }

      const fullNameSlugify = UtilsService.slugify(e.fullName);
      const fullNameMatches = fullNameSlugify.toLowerCase().includes(textSlugify);
      if (fullNameMatches) {
        return true;
      }

      const nickNameSlugify = UtilsService.slugify(e.nickName);
      const nickNameMatches = nickNameSlugify.toLowerCase().includes(textSlugify);
      if (nickNameMatches) {
        return true;
      }

      return false;
    });

    if (!result?.length) {
      this.showThreeLetterWarning = false;
      this.showNoResultWarning = true;
    }

    return result;
  }

  getRecentSearches() {
    const storageRecentSearches = localStorage.getItem(this.storage);
    if (storageRecentSearches) {
      this.recentSearches = JSON.parse(storageRecentSearches);
      this.cdr.detectChanges();
    }
  }

  addToRecentSearches(recent: Person) {
    this.recentSearches.unshift(recent);
    let uniqueList = UtilsService.removeDuplicatesByKey(this.recentSearches, 'id');
    uniqueList = uniqueList.slice(0, 5);
    const data = JSON.stringify(uniqueList);
    localStorage.setItem(this.storage, data);
  }

  onItem(item: any) {
    if (item.title) {
      this.onSurname(item);
    } else if (item.region) {
      this.onCity(item);
    } else {
      this.onBiography(item);
    }
  }

  onSurname(item: Person) {
    this.navigate(`prezime/${item.slug}`);
  }

  onCity(item: Person) {
    this.navigate(`tag/${item.slug}/podaci`, item);
  }

  onBiography(item: Person) {
    this.navigate(`biografija/${item.slug}`, item);
  }

  goToCategories() {
    this.navigate(`kategorije`);
  }

  goToTags() {
    this.navigate(`tagovi`);
  }

  goToSurnames() {
    this.navigate(`prezime`);
  }


  goToBirthdays() {
    const path = UtilsService.getTodaySlug();
    this.navigate(`kalendar/${path}/rodjeni`);
  }

  navigate(path: string, item?: any) {
    this.router.navigateByUrl(path)
      .then(() => {
        this.changeDetectorRef.detectChanges();
        this.dialogRef.close();
        if (item && !item.title) {
          this.addToRecentSearches(item);
        }
      });
  }

  onClose() {
    this.dialogRef.close(true);
  }
}
