import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export enum SongType {
  patriot = 'patriot',
  love = 'love',
  children = 'children',
  elegy = 'elegy',
  satire = 'satire',
  epic = 'epic',
  nature = 'nature',
  holiday = 'holiday',
  other = 'other',
}

export interface Song {
  id?: string;
  innerHtml: string | null;
  slug: string | null;
  title: string | null;
  type: SongType | null;
  authorSlug: string | null;
  authorName: string | null;
  important: boolean | null;
  created: number | null;
  updated: number | null;
}

export const songTypes: any[] = [
  { id: SongType.patriot, name: 'Родољубива поезија' },
  { id: SongType.love, name: 'Љубавнa поезија' },
  { id: SongType.children, name: 'Дечја поезија' },
  { id: SongType.elegy, name: 'Елегија' },
  { id: SongType.satire, name: 'Сатира' },
  { id: SongType.epic, name: 'Епска поезија' },
  { id: SongType.nature, name: 'Лирска поезија' },
  { id: SongType.holiday, name: 'Празнична поезија' },
];

@Injectable({ providedIn: 'root' })
export class SongsService extends CrudService<Song> {
  override collectionName = 'songs';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}
