import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Storage } from '@angular/fire/storage';
import { CrudService } from '@services/firestore-crud.service';

export interface Instagram {
  id?: string | null;
  title: string | null;
  slug: string | null;
  type?: InstagramPostType | null;
  embeddedCode: string | null;
  likes?: number | null;
  comments?: number | null;
  shares?: number | null;
  saves?: number | null;
  reach?: number | null;
  created: number | null;
  updated: number | null;
}

export enum InstagramPostType {
  quotes = 'quotes',
  city = 'city',
  nature = 'nature',
  biography = 'biography',
  sport = 'sport',
  categories = 'categories',
  events = 'events',
  dinasties = 'dinasties',
  chronology = 'chronology',
  art = 'art',
  saints = 'saints',
  other = 'other',
}

export enum InstagramPostSlugs {
  quotes = 'citati-i-stihovi',
  city = 'gradovi-i-opstine',
  nature = 'priroda',
  biography = 'biografija',
  sport = 'sport',
  categories = 'kategorije',
  events = 'istorijski-dogadjaji',
  dinasties = 'dinastije',
  saints = 'pravoslavlje',
  art = 'umetnost',
  other = 'ostalo',
}

export const instragramTypes: any[] = [
  {
    id: InstagramPostType.events,
    image: `url('assets/images/bg-battle.jpg')`,
    link: InstagramPostSlugs.events,
    name: 'Историјски догађаји',
    order: 1,
  },
  {
    id: InstagramPostType.dinasties,
    image: `url('assets/images/bg-state.jpg')`,
    link: InstagramPostSlugs.dinasties,
    name: 'Династије',
    order: 2,
  },
  {
    id: InstagramPostType.biography,
    image: `url('assets/images/bg-bio.png')`,
    link: InstagramPostSlugs.biography,
    name: 'Биографије',
    order: 3,
  },
  {
    id: InstagramPostType.saints,
    image: `url('assets/images/bg-church.jpg')`,
    link: InstagramPostSlugs.saints,
    name: 'Православље',
    order: 4,
  },
  {
    id: InstagramPostType.quotes,
    image: `url('assets/images/bg-quotes.jpg')`,
    link: InstagramPostSlugs.quotes,
    name: 'Цитати',
    order: 5,
  },
  {
    id: InstagramPostType.art,
    image: `url('assets/images/bg-art.jpg')`,
    link: InstagramPostSlugs.art,
    name: 'Уметност',
    order: 6,
  },
  {
    id: InstagramPostType.nature,
    image: `url('assets/images/bg-nature.jpg')`,
    link: InstagramPostSlugs.nature,
    name: 'Природа и здања',
    order: 7,
  },
  {
    id: InstagramPostType.sport,
    image: `url('assets/images/bg-sport.jpg')`,
    link: InstagramPostSlugs.sport,
    name: 'Спорт',
    order: 8,
  },
  {
    id: InstagramPostType.city,
    image: `url('assets/images/bg-city.jpg')`,
    link: InstagramPostSlugs.city,
    name: 'Градови и општине',
    order: 9,
  },
  {
    id: InstagramPostType.categories,
    image: `url('assets/images/bg-movie.jpg')`,
    link: InstagramPostSlugs.categories,
    name: 'Категорије',
    order: 10,
  },
  {
    id: InstagramPostType.other,
    image: `url('assets/images/bg-art.jpg')`,
    link: InstagramPostSlugs.other,
    name: 'Остало',
    order: 11,
  },
];

@Injectable({ providedIn: 'root' })
export class InstagramService extends CrudService<Instagram> {
  override collectionName = 'instagram';

  constructor(firestore: Firestore, storage: Storage) {
    super(firestore, storage);
  }
}
