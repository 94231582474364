@if (mainTitle) {
<h5 class="headline"
  (click)="method ? method() : null">
  <span class="headline-title gagalin">{{mainTitle | translit}}</span>
  <p class="headline-subtitle gagalin">{{subtitle | translit}}</p>
</h5>
}

@if(showScroll) {
<div class="scroll-container"
  [class.text-only]="textOnly"
  [ngClass]="name">
  <ng-content></ng-content>
</div>
}