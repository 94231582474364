import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { SearchDialogComponent } from '@app/dialogs/search-dialog/search-dialog.component';
import { AuthService } from '@app/services/auth.service';
import { UtilsService } from '@app/services/utils.service';

@Component({
  selector: 'app-bottom-menu',
  imports: [MatToolbarModule, MatButtonModule, MatDialogModule, MatIconModule],
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomMenuComponent {
  private router = inject(Router);
  private location = inject(Location);
  private matDialog = inject(MatDialog);
  public authService = inject(AuthService);
  private destroyRef = inject(DestroyRef);
  private isAdmin = false;

  ngOnInit(): void {
    this.checkAdmin();
  }
  checkAdmin(): void {
    this.authService.userProfile$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(user => this.isAdmin = !!user?.isAdmin);
  }
  onSearch() {
    const href = this.router.url;
    this.location.go(href + '#');

    const dialogRef = this.matDialog.open(SearchDialogComponent, {
      panelClass: 'search-dialog-container',
      scrollStrategy: new NoopScrollStrategy(),
    });

    dialogRef.afterClosed().subscribe((onX: boolean) => {
      if (onX) {
        this.location.back();
      } else {
        if (window.location.href.endsWith('#')) {
          this.location.back();
        };
      }
    });
  }

  onHeritage() {
    this.router.navigateByUrl(`srpsko-nasledje`);
  }

  onQuotes() {
    this.router.navigateByUrl('citati');
  }

  onHome() {
    this.router.navigateByUrl(`/`);
  }

  onCalendar() {
    const path = UtilsService.getTodaySlug();
    this.router.navigateByUrl(`kalendar/${path}/rodjeni`);
  }

  onChronology() {
    this.router.navigateByUrl(`vremeplov`);
  }

  onAdmin() {
    if (this.isAdmin) {
      this.router.navigateByUrl(`admin/panel/notes-list`);
    }
  }

}
